import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

class SwiperInitializer {
  private swipers: Swiper[] = [];

  constructor() {
    this.initializeSwipers();
  }

  private initializeSwipers(): void {
    const swiperElements =
      document.querySelectorAll<HTMLElement>("[data-swiper]");

    swiperElements.forEach((element, index) => {
      const prevButton = element.querySelector<HTMLButtonElement>("#prevSlide");
      const nextButton = element.querySelector<HTMLButtonElement>("#nextSlide");
      const pagination =
        element.querySelector<HTMLElement>(".swiper-pagination");

      if (!prevButton || !nextButton || !pagination) {
        console.error(`Swiper ${index}: Missing required elements`);
        return;
      }

      // Start of Selection
      const swiper = new Swiper(element, {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        navigation: {
          prevEl: prevButton,
          nextEl: nextButton,
        },
        pagination: {
          el: pagination,
          clickable: true,
        },
        breakpoints: {
          640: {
            // Adjust the breakpoint width as needed
            slidesPerView: 2,
            spaceBetween: 30,
          },
        },
      });

      this.swipers.push(swiper);
    });
  }

  public destroySwipers(): void {
    this.swipers.forEach((swiper) => swiper.destroy());
    this.swipers = [];
  }
}

// Initialize swipers when the DOM is fully loaded
document.addEventListener("DOMContentLoaded", () => {
  const swiperInitializer = new SwiperInitializer();

  // Optionally, you can expose the initializer to the global scope for further manipulation
  (window as any).swiperInitializer = swiperInitializer;
});

export default SwiperInitializer;
