export default () => ({
  init() {
    if (!window.Plyr) {
      import("https://cdn.plyr.io/3.7.8/plyr.js").then(() => {
        const player = new Plyr(this.$refs.audio);
        player.play();
      });
    }
  },
});
